import { useEffect, useRef, useState } from 'react'

export function ConfigurableEmbed({
  embeddedSourceUrl,
}: {
  embeddedSourceUrl: string
}) {
  const [iframeHeight, setIframeHeight] = useState<string | number>(0)
  const iframeRef = useRef(null)

  useEffect(() => {
    const onMessage = (event) => {
      if (event.origin !== 'https://shop.noissue.co') {
        return
      }
      // TODO: Add an extra check for the event name and payload ?
      setIframeHeight(event?.data?.height?.height || 'auto')
    }

    window.addEventListener('message', onMessage)

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [])

  return (
    <iframe
      ref={iframeRef}
      src={embeddedSourceUrl}
      height={iframeHeight ? iframeHeight : 'auto'}
      className="w-full min-h-[100vh] no-scrollbar"
    />
  )
}
